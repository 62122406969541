//#region IMPORTS

import React from 'react';
import { Route, Switch, withRouter } from 'react-router-dom';
import { withApollo } from 'react-apollo';
import get from 'lodash/get';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.min.css';
import uniqBy from 'lodash/uniqBy';

import './index.css';
import routes from './routes';
import ApplyNow from './controllers/ApplyNow';
import FloorplanCreateProfile from './controllers/FloorplanCreateProfile';
import UserAccountOverview from './controllers/UserAccountOverview';
import UserQuoteDetails from './controllers/UserQuoteDetails';
import { pagesQuery } from './graphql/queries/pagesQuery';
import { redirectsQuery } from './graphql/queries/redirectsQuery';
import { allDataQuery } from './graphql/queries/allDataQuery';
import config from './config';
import Routes from './helpers/prismic/routes';
import ResidentPortalFeedback from './controllers/ResidentPortalFeedbackController';
import GroupPropertiesByRegion from './helpers/group-properties-by-region';
import ContactForm from './controllers/ContactFormController';
import Blog from './controllers/BlogController';
import InitialLoader from './InitialLoader';
import PagePreview from './helpers/page-preview';
import { canUseDOM } from './helpers/utils';
import ForgotPassword from './controllers/ForgotPassword';
import ContactSuccess from './controllers/ContactSuccess';
import LocalStorageCleaner from './helpers/local-storage-cleaner';
import RoutesContext from './helpers/routes-context';
import { setItem } from './helpers/cookies';
import { DataValidator } from './helpers/prismic/data-validator';
import NavigationPatcher from './components/NavigationPatcher';

let Page404 = null;

try {
  Page404 = require('./views').NotFoundView;
} catch (e) {
  // ignore dynamic import failures
  console.log(e);
}

//#endregion

class App extends React.Component {
  //#region CONSTRUCTORS

  constructor(props) {
    super(props);
    this.state = {
      routes: props.routes,
      allPageData: props.pageData,
      loaded: props.location.pathname !== '/',
      banner: props.banner,
      redirects: null
    };
  }

  //#endregion

  //#region METHODS
  static getAllPages = async client => {
    try {
      const pagesData = await DataValidator(
        client.query({
          query: allDataQuery,
          variables: {
            full: false
          }
        })
      );
      const pages = get(pagesData, 'data.allData');
      return pages;
    } catch (e) {
      console.log('App.js - getAllPages');
    }
  };

  static getRedirects = async client => {
    try {
      const redirectsData = await DataValidator(
        client.query({
          query: redirectsQuery
        })
      );

      return redirectsData;
    } catch (e) {
      console.log('App.js - getRedirects');
    }
  };

  static getRoutes = async (client, full = false, setLoaded = () => {}, ignoreTags = false) => {
    try {
      const pagesData = await DataValidator(
        client.query({
          query: pagesQuery,
          ...(full ? { variables: { full } } : {})
        })
      );

      const pages = get(pagesData, 'data.pages');
      const regions = (pages || []).filter(page => !!page && !!page.data && !!page.type && page.type === 'region');

      const properties = (pages || []).filter(page => !!page && !!page.data && !!page.type && page.type === 'property');

      const subregions = (pages || []).filter(page => !!page && !!page.data && !!page.type && page.type === 'sub-region');

      const allPageData = {
        properties,
        regions,
        subregions
      };

      const dynamicRoutes = Routes(pages, setLoaded, ignoreTags) || [];
      return { dynamicRoutes, allPageData, pages };
    } catch (e) {
      console.log('App.js - getRoutes');
    }
  };
  //#endregion

  //#region EVENTS

  //#region PROCESS api calls & set state
  componentWillMount() {
    if (!process.browser || !canUseDOM) {
      //#region PROCESS when app running on server
      return true;
      //#endregion
    } else {
      //#region PROCESS when app running on client

      if (!window.__PAGES_DATA__) {
        return true;
      }

      //#region STORE globall route and content data

      //NOTE: See the "injectHTML" method inside frontend/server/homeloader.js or loader.js for window.__PAGES_DATA__ data population
      const pageData = JSON.parse(unescape(window.__PAGES_DATA__));

      //NOTE: pageData.allPageData = frontend/server/example_allPageData.json data structure

      //NOTE: pageData.pages = frontend/server/example_slimpages.json data structure (EXAMPLE: CONTAINS ABOUT US ROUTE DATA)
      //NOTE: pageData.pages = USED TO GENERATE ROUTES

      this.setState({
        routes: this.props.routes || !!window.__PAGES_DATA__ ? Routes(pageData.pages || []) : null,
        allPageData: this.props.pageData || !!window.__PAGES_DATA__ ? pageData.allPageData : null,
        loaded: this.props.location.pathname !== '/',
        banner: this.props.banner || !!window.__PAGES_DATA__ ? pageData.banner : null
      });

      //#endregion

      //#endregion
    }
  }
  //#endregion

  //#region PROCESS something before displaying to user
  async componentDidMount() {
    //#region STORE globally referring website site
    if (canUseDOM) {
      const referralSite = document.referrer;
      setItem('referralSite', referralSite);
    }
    //#endregion

    //#region STORE locally navigated path
    const pathname = get(this.props, 'location.pathname');
    //#endregion

    //#region OUTPUT page loader
    if (pathname === '/') {
      await this.setState({ loaded: true });
    }
    //#endregion

    //#region STORE locally did we lose route information
    const hasServerRouteData = !((!this.props.routes || !this.props.pageData) && (!this.state.routes || !this.state.allPageData));
    //#endregion

    //#region STORE locally server routes

    const serverRoutes = [
      ...(Array.isArray(this.props.routes) ? this.props.routes : []),
      ...(Array.isArray(this.state.routes) ? this.state.routes : [])
    ];

    //#endregion

    if (!hasServerRouteData || serverRoutes.length < 2) {
      //#region STORE globally refreshed routes
      const { dynamicRoutes, allPageData } = await App.getRoutes(this.props.client, config.IS_DEV, () => {});

      await this.setState({
        routes: uniqBy([...(this.state.routes || []), ...(dynamicRoutes || [])], 'key'),
        allPageData
      });
      //#endregion
    }

    //#region OUTPUT page loader
    if (pathname === '/') {
      await this.setState({
        loaded: true
      });
    }
    //#endregion
  }
  //#endregion

  //#region OUTPUT selected route HTML content

  render() {
    let dynamicRoutes = [
      ...(Array.isArray(this.props.routes) ? this.props.routes : []),
      ...(Array.isArray(this.state.routes) ? this.state.routes : [])
    ];

    const pathname = get(this.props, 'location.pathname');

    if (dynamicRoutes.length === 0 && pathname !== '/') {
      return (
        <div
          style={{
            alignSelf: 'center',
            width: '100%',
            zIndex: 99999,
            height: '100vh',
            textAlign: 'center',
            fontSize: 25,
            paddingTop: 25,
            fontFamily: '"Outfit", sans-serif',
            backgroundColor: 'white'
          }}
        >
          Loading...
        </div>
      );
    }

    if (process.env.NODE_ENV !== 'production') {
      dynamicRoutes = [...dynamicRoutes, ...(routes() || [])];
    }

    let regionProperties;
    const allPageData = this.state.allPageData || this.props.pageData;
    if (!!allPageData && !!allPageData.regions && !!allPageData.properties) {
      regionProperties = GroupPropertiesByRegion(allPageData);
    }

    return (
      <RoutesContext.Provider
        value={{
          routes: [],
          addToRoutes: routes => {
            const newRoutes = Routes(routes);
            this.setState({
              routes: uniqBy([...(this.state.routes || []), ...(newRoutes || [])], 'key')
            });
          }
        }}
      >
        <ToastContainer position="top-center" autoClose={2000} hideProgressBar newestOnTop={false} closeOnClick rtl={false} draggable pauseOnHover />
        <NavigationPatcher />
        <div
          id="google_translate_element"
          style={{
            zIndex: -1000,
            position: 'absolute',
            opacity: 0
          }}
        />
        {pathname === '/' ? (
          <InitialLoader loaded={this.state.loaded} banner={this.props.banner || this.state.banner} loader={this.props.loader} />
        ) : null}
        <Switch>
          {dynamicRoutes}
          {/*<Route
                path="/component/:component?"
                component={ComponentRenderer}
                exact
              />*/}
          <Route path="/preview-page" component={PagePreview} exact />
          <Route
            path="/forgot-password"
            render={() => (
              <LocalStorageCleaner>
                <ForgotPassword exact />
              </LocalStorageCleaner>
            )}
          />
          <Route
            path="/applynow"
            exact
            render={() => (
              <LocalStorageCleaner>
                <ApplyNow communities={regionProperties} />
              </LocalStorageCleaner>
            )}
          />
          <Route path="/create-profile" component={FloorplanCreateProfile} exact />
          <Route
            path="/blog"
            exact
            render={() => (
              <LocalStorageCleaner>
                <Blog communities={regionProperties} exact />
              </LocalStorageCleaner>
            )}
          />
          <Route
            path="/contact"
            exact
            render={() => (
              <LocalStorageCleaner>
                <ContactForm communities={regionProperties} />
              </LocalStorageCleaner>
            )}
          />
          <Route
            path="/account"
            render={() => (
              <LocalStorageCleaner>
                <UserAccountOverview exact />
              </LocalStorageCleaner>
            )}
          />
          <Route
            path="/resident-portal"
            exact
            render={() => (
              <LocalStorageCleaner>
                <ResidentPortalFeedback communities={regionProperties} />
              </LocalStorageCleaner>
            )}
          />
          <Route path="/quote/:id" component={UserQuoteDetails} exact />
          <Route path="/contact-success" render={() => <ContactSuccess communities={regionProperties} exact />} />
          <Route path="/robots.txt" exact />
          {!!Page404 ? (
            <Route
              render={() => (
                <LocalStorageCleaner>
                  <Page404.Controller />
                </LocalStorageCleaner>
              )}
            />
          ) : null}
        </Switch>
      </RoutesContext.Provider>
    );
  }
  //#endregion

  //#endregion
}

export default withRouter(withApollo(App));
